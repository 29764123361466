import React from "react";
import { createUseStyles } from "react-jss";

import { MIN_TABLET_MEDIA_QUERY } from "typography-breakpoint-constants";

import Layout from "../components/layout";
import PageTitle from "../components/pageTitle";
import ProjectCard from "../components/projectCard";
import SEO from "../components/seo";
import useProjects from "../hooks/projects";

const useStyles = createUseStyles({
    projectsGrid: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: "1rem",
        [MIN_TABLET_MEDIA_QUERY]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
    },
});

const ProjectsPage = () => {
    const classes = useStyles();

    const projects = useProjects();

    return (
        <Layout>
            <SEO title="Projects" />
            <PageTitle title="Projects" />
            <p>A collection of (some) of my most interesting projects. Mostly from my unviersity courses.</p>
            <section className={classes.projectsGrid}>
                {projects.map(({ node: { id, frontmatter } }) => <ProjectCard key={id} project={frontmatter} />)}
            </section>
        </Layout>
    );
};

export default ProjectsPage;
