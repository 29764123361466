import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

import IconLink from "./iconLink";

import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = createUseStyles({
    card: {
        minWidth: "200px",
        height: "100%",
        background: "var(--card-bg)",
        boxShadow: "0px 0px 4px 0px var(--box-shadow-color)",
        padding: ".5em 1em 1em 1em",
        display: "flex",
        flexDirection: "column",
        "& header": {
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
        },
        "& footer": {
            color: "var(--accent-color)",
            fontSize: "0.8rem",
            padding: "0 .8em",
        },
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        flexGrow: "1",
        margin: "0 .8rem",
        "& > h4": {
            margin: "0",
            fontSize: "1.1rem",
            "& > a": {
                boxSizing: "content-box",
                paddingBottom: "0.07em",
            },
        },
        "& > span": {
            color: "var(--solid-grey)",
            fontSize: "0.9rem",
        },
    },
    github: {
        fontSize: "1.3rem",
    },
    size: {
        color: "var(--solid-grey)",
        "& > span": {
            marginRight: ".3rem",
        },
    },
    content: {
        padding: "0 .8em",
    },
    description: {
        margin: "1rem 0",
        fontSize: "0.9rem",
    },
});

export const ProjectCard = ({ project: { github, title, description, location, size, stack, url } }) => {
    const classes = useStyles();
    return (
        <div className={classes.card}>
            <header>
                <div className={classes.github}>
                    <IconLink href={github} icon={faGithub} />
                </div>
                <div className={classes.title}>
                    {title &&
                    <h4>
                        {url ?
                            <a href={url} target="_blank" rel="noreferrer">{title}</a> :
                            title
                        }
                    </h4>
                    }
                    {location &&
                    <span>{location}</span>
                    }
                </div>
                <div className={classes.size}>
                    <span>{size || "1"}</span>
                    <FontAwesomeIcon icon={faUser} />
                </div>
            </header>
            <div className={classes.content}>
                <p className={classes.description}>
                    {description}
                </p>
            </div>
            <footer>
                {stack.join(" ")}
            </footer>
        </div>
    );
};

ProjectCard.propTypes = {
    project: PropTypes.shape({
        github: PropTypes.string,
        title: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        size: PropTypes.string,
        description: PropTypes.string.isRequired,
        stack: PropTypes.arrayOf(PropTypes.string).isRequired,
        url: PropTypes.string,
    }).isRequired,
};

export default ProjectCard;
