import { useStaticQuery, graphql } from "gatsby";

export default () => {
    const { allMarkdownRemark: { edges } } = useStaticQuery(
        graphql`
  query Projects {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {glob: "**/content/projects/*.md"}}, 
        sort: { order: [DESC, ASC, ASC], fields: [frontmatter___date, frontmatter___title, frontmatter___location] }
      ) {
      edges {
        node {
          id
          frontmatter {
            date
            description
            github
            location
            size
            stack
            title
            url
          }
        }
      }
    }
  }
`);
    return edges;
};
